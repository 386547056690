import React from 'react';

function ResizeOptions({ options, onChange }) {
  return (
    <div className="space-y-4">
      <div>
        <label className="block text-sm font-medium text-gray-700">
          Output Format
        </label>
        <select
          value={options.format}
          onChange={(e) => onChange({ ...options, format: e.target.value })}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
        >
          <option value="jpg">JPG</option>
          <option value="png">PNG</option>
          <option value="webp">WEBP</option>
        </select>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">
          Quality ({options.quality}%)
        </label>
        <input
          type="range"
          min="1"
          max="100"
          value={options.quality}
          onChange={(e) => onChange({ ...options, quality: parseInt(e.target.value) })}
          className="w-full"
        />
      </div>
    </div>
  );
}

export default ResizeOptions; 