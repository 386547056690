import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ImageUploader from './components/ImageUploader';
import ImagePreview from './components/ImagePreview';
import ResizeOptions from './components/ResizeOptions';
import ProcessedImage from './components/ProcessedImage';

const BASE_URL = process.env.NODE_ENV === 'production'
  ? ''  // Empty string for production as we're using relative paths
  : 'http://localhost:3002';

const API_URL = `${BASE_URL}/api/images/process`;

function App() {
  const [uploadedImages, setUploadedImages] = useState([]);
  const [processedImages, setProcessedImages] = useState([]);
  const [resizeOptions, setResizeOptions] = useState({
    format: 'jpg',
    quality: 90
  });
  const [isProcessing, setIsProcessing] = useState(false);
  const [isServerAvailable, setIsServerAvailable] = useState(true);

  // Check server health on component mount
  useEffect(() => {
    const checkServerHealth = async () => {
      try {
        await axios.get(`${BASE_URL}/api/health`);
        setIsServerAvailable(true);
      } catch (error) {
        console.error('Server health check failed:', error);
        setIsServerAvailable(false);
      }
    };
    checkServerHealth();
  }, []);

  const handleImageUpload = (newImages) => {
    setUploadedImages(prev => [...prev, ...newImages]);
  };

  const handleImageDelete = (index) => {
    setUploadedImages(prev => prev.filter((_, i) => i !== index));
  };

  const handleResize = async () => {
    setIsProcessing(true);
    try {
      const processedResults = await Promise.all(
        uploadedImages.map(async (image) => {
          const formData = new FormData();
          const imageFile = image instanceof File ? image : new File([image], image.name, { type: image.type });
          formData.append('image', imageFile);
          formData.append('format', resizeOptions.format);
          formData.append('quality', resizeOptions.quality.toString());

          const response = await axios.post(API_URL, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });

          return {
            ...response.data,
            originalName: image.name
          };
        })
      );

      setProcessedImages(processedResults);
    } catch (error) {
      console.error('Error processing images:', error);
      alert('Error processing images. Please try again.');
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-8">Image Size Reducer</h1>
      
      <ImageUploader 
        onUpload={handleImageUpload} 
        uploadedImages={uploadedImages}
        onDelete={handleImageDelete}
      />
      
      <div className="mt-8">
        <ResizeOptions
          options={resizeOptions}
          onChange={setResizeOptions}
        />
      </div>

      {uploadedImages.length > 0 && (
        <button
          onClick={handleResize}
          disabled={isProcessing}
          className={`mt-8 px-6 py-2 rounded text-white ${
            isProcessing 
              ? 'bg-blue-300 cursor-not-allowed' 
              : 'bg-blue-500 hover:bg-blue-600'
          }`}
        >
          {isProcessing ? 'Processing...' : 'Resize Images'}
        </button>
      )}

      <div className="mt-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {processedImages.map((image, index) => (
          <ProcessedImage key={index} image={image} />
        ))}
      </div>
    </div>
  );
}

export default App; 