import React from 'react';

function ProcessedImage({ image }) {
  const { data: info, buffer, originalName } = image;
  
  // Create the new filename with _resized suffix
  const getResizedFileName = () => {
    const lastDotIndex = originalName.lastIndexOf('.');
    if (lastDotIndex === -1) {
      // If no extension in original name
      return `${originalName}_resized.${info.format}`;
    }
    // Insert _resized before the extension
    const nameWithoutExt = originalName.substring(0, lastDotIndex);
    return `${nameWithoutExt}_resized.${info.format}`;
  };
  
  return (
    <div className="border rounded-lg p-4">
      <img
        src={`data:image/${info.format};base64,${buffer}`}
        alt="Processed"
        className="w-full h-48 object-cover rounded"
      />
      <div className="mt-4">
        <p className="text-sm text-gray-500">
          Format: {info.format.toUpperCase()}
        </p>
        <p className="text-sm text-gray-500">
          Size: {(info.size / 1024).toFixed(2)} KB
        </p>
        <p className="text-sm text-gray-500">
          Dimensions: {info.width} x {info.height}
        </p>
        <a
          href={`data:image/${info.format};base64,${buffer}`}
          download={getResizedFileName()}
          className="mt-2 inline-block text-blue-500 hover:text-blue-600"
        >
          Download
        </a>
      </div>
    </div>
  );
}

export default ProcessedImage; 