import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

const MAX_FILE_SIZE = 30 * 1024 * 1024; // 30MB
const SUPPORTED_FORMATS = ['image/jpeg', 'image/png', 'image/webp', 'image/tiff'];

function ImageUploader({ onUpload, uploadedImages, onDelete }) {
  const onDrop = useCallback((acceptedFiles) => {
    const validFiles = acceptedFiles.filter(file => {
      if (file.size > MAX_FILE_SIZE) {
        alert(`File ${file.name} is too large. Maximum size is 30MB`);
        return false;
      }
      if (!SUPPORTED_FORMATS.includes(file.type)) {
        alert(`File ${file.name} has unsupported format`);
        return false;
      }
      return true;
    });

    const filesWithPreview = validFiles.map(file => Object.assign(file, {
      preview: URL.createObjectURL(file)
    }));

    onUpload(filesWithPreview);
  }, [onUpload]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'image/*': SUPPORTED_FORMATS
    }
  });

  return (
    <div
      {...getRootProps()}
      className={`border-2 border-dashed rounded-lg p-8 text-center cursor-pointer min-h-[300px] relative
        ${isDragActive ? 'border-blue-500 bg-blue-50' : 'border-gray-300'}`}
    >
      <input {...getInputProps()} />
      
      {uploadedImages.length === 0 ? (
        <>
          <p className="text-gray-600">
            {isDragActive
              ? "Drop the images here..."
              : "Drag 'n' drop images here, or click to select files"}
          </p>
          <p className="text-sm text-gray-500 mt-2">
            Supported formats: JPG, PNG, WEBP, TIFF (Max size: 30MB)
          </p>
        </>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {uploadedImages.map((image, index) => (
            <div key={index} className="relative group">
              <img
                src={image.preview}
                alt={image.name}
                className="w-full h-48 object-cover rounded"
              />
              <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-40 transition-all duration-300 rounded">
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    onDelete(index);
                  }}
                  className="absolute top-2 right-2 bg-red-500 text-white p-2 rounded-full opacity-0 group-hover:opacity-100 transition-opacity duration-300"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                  </svg>
                </button>
                <div className="absolute bottom-0 left-0 right-0 p-2 bg-black bg-opacity-50 text-white text-sm opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                  <p className="truncate">{image.name}</p>
                  <p className="text-xs">{(image.size / 1024).toFixed(2)} KB</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
      
      {uploadedImages.length > 0 && (
        <p className="text-sm text-gray-500 mt-4">
          Drop more images or click to select more files
        </p>
      )}
    </div>
  );
}

export default ImageUploader; 